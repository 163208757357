import { createStore, combineReducers, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import ReduxThunk from 'redux-thunk';
import CustomErrorHandler from '~global/utils/custom-error-handler';
import app from './app/slice';
import auth from './user/reducers/auth';
import banners from './banners/slice';
import catalog from './catalog/reducers/catalog';
import cart from './cart/reducers/cart';
import voucher from './cart/reducers/voucher';
import address from './address/slice';
import location from './location/slice';
import shipping from './shipping/slice';
import profiling from './profiling/slice';
import promotion from './promotion/slice';
import search from './search/slice';
import help from './help/slice';
import tracker from './tracker/slice';
import payment from './payment/paymentSlice';
import modal from './modal/reducers/modal';
import orders from './orders/ordersSlice';
import creditDebitCard from './credit-debit-card/creditDebitCardSlice';
import productFilters from './product-filters/productFiltersSlice';
import categories from './categories/slice';
import brand from './brand/brandSlice';
import newArrival from './new-arrival/newArrivalSlice';
import bestSeller from './best-seller/bestSellerSlice';
import productDetail from './product-detail/slice';
import campaign from './campaign/slice';
import campaignV2 from './campaignV2/slice';

import myReviews from './my-reviews/slice';
import createReview from './create-review/slice';
import editReview from './edit-review/slice';
import addPhoto from './add-photo/slice';
import careers from './careers/slice';
import flashSale from './flash-sale/slice';
import pos from './pos/slice';
import home from './home/slice';
import shoppingBag from './shopping-bag/slice';
import egiftList from './e-gift-list/egiftListSlice';
import babyRegistry from './baby-registry/slice';
import gifting from './gifting/slice';
import membership from './membership/slice';
import momChecklist from './mom-checklist/slice';
import article from './articles/slice';
// Revamp V2
import newProductFilters from './product-filters/v2/productFiltersSlice';
import newLillaCategory from './new-lilla-category/slice';

// .........Store
const rootReducer = combineReducers({
  app,
  auth,
  banners,
  catalog,
  cart,
  payment,
  modal,
  voucher,
  address,
  location,
  shipping,
  orders,
  creditDebitCard,
  profiling,
  promotion,
  search,
  help,
  tracker,
  productFilters,
  categories,
  brand,
  newArrival,
  bestSeller,
  productDetail,
  campaign,
  campaignV2,
  myReviews,
  createReview,
  editReview,
  addPhoto,
  careers,
  flashSale,
  pos,
  home,
  shoppingBag,
  egiftList,
  babyRegistry,
  membership,

  // Revamp V2
  newProductFilters,
  gifting,
  momChecklist,
  article,
  newLillaCategory,
});
const errorHandler = new CustomErrorHandler();
const middlewares = [];
middlewares.push(ReduxThunk);

function logger({ getState }) {
  return (next) => (action) => {
    if (/rejected/gi.test(action.type)) {
      const error = JSON.parse(JSON.stringify(action.error));
      error.meta = JSON.parse(JSON.stringify(action.meta));
      error.type = JSON.parse(JSON.stringify(action.type));
      errorHandler.handleNodeError(error);
    }

    // Call the next dispatch method in the middleware chain.
    const returnValue = next(action);

    // console.log('state after dispatch', getState('home'))

    // This will likely be the action itself, unless
    // a middleware further in chain changed it.
    return returnValue;
  };
}

middlewares.push(logger);

// eslint-disable-next-line no-undef
// if (__DEV__) {
//   const createDebugger = require('redux-flipper').default;
//   middlewares.push(createDebugger());
// }

const store = createStore(
  rootReducer,
  // composeWithDevTools(applyMiddleware(ReduxThunk)),
  // applyMiddleware(...middlewares)
  composeWithDevTools(applyMiddleware(...middlewares)),
);

export default store;
