import { Catalog } from '~api/catalog';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const getProducts = createAsyncThunk(
  'gifting/getProducts',
  async (params) => {
    const response = await Catalog.getProductsRequest(params);
    return response.data;
  },
);

export const getTotalProducts = createAsyncThunk(
  'gifting/getTotalProducts',
  async (params) => {
    const response = await Catalog.getTotalProductsRequest(params);
    return response.data;
  },
);

export const loadMoreProducts = createAsyncThunk(
  'gifting/loadMoreProducts',
  async (params) => {
    const response = await Catalog.getProductsRequest(params);
    return response.data;
  },
);
