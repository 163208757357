import { createSlice } from '@reduxjs/toolkit';
import { getProducts, getTotalProducts, loadMoreProducts } from './thunks';
import { isWeb } from '~global/screens';
const giftingSlice = createSlice({
  name: 'gifting',
  initialState: {
    products: [],
    totalProducts: '...',
    status: {
      products: 'idle',
      totalProducts: 'idle',
      loadMoreProducts: 'idle',
    },
  },
  reducers: {
    setState(state, { value, name }) {
      state[name] = value;
    },
    setStatusApi(state, { value, name }) {
      state.status[name] = value;
    },
    resetGiftingState(state) {
      this.products = [];
      this.totalProducts = '...';
      this.status = {
        products: 'idle',
        totalProducts: 'idle',
        loadMoreProducts: 'idle',
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProducts.pending, (state) => {
        state.status.products = 'loading';
      })
      .addCase(getProducts.rejected, (state) => {
        state.status.products = 'error';
      })
      .addCase(getProducts.fulfilled, (state, { payload }) => {
        state.products = payload.data;
        state.status.products = 'succeeded';
      })
      .addCase(getTotalProducts.pending, (state) => {
        state.status.totalProducts = 'loading';
      })
      .addCase(getTotalProducts.rejected, (state) => {
        state.status.totalProducts = 'error';
      })
      .addCase(getTotalProducts.fulfilled, (state, { payload }) => {
        state.totalProducts = payload.data;
        state.status.totalProducts = 'succeeded';
      })
      .addCase(loadMoreProducts.pending, (state) => {
        state.status.loadMoreProducts = 'loading';
      })
      .addCase(loadMoreProducts.rejected, (state) => {
        state.status.loadMoreProducts = 'error';
      })
      .addCase(loadMoreProducts.fulfilled, (state, { payload }) => {
        if (isWeb) {
          state.products = payload.data;
        } else {
          state.products = [...state.products, ...payload.data];
        }

        state.status.loadMoreProducts = 'succeeded';
      });
  },
});

export default giftingSlice.reducer;
