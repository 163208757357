import { createSlice } from '@reduxjs/toolkit';
import {
  getSearchResult,
  getOrdersCount,
  getOrders,
  getCategories,
  getFaqs,
  postLikes,
  postDislikes,
  uploadImages,
  postFeedback,
} from '~store/help/thunks';

const search = {
  data: [],
  categories: [],
};
const result = {
  keyword: '',
  data: [],
};
const orders = {
  index: -1,
  count: 0,
  data: [],
  params: {
    filter: {
      order_source: { $in: ['lilla', 'lulla', 'lulla_ios', 'lulla_android'] },
    },
    skip: 0,
    limit: 10,
  },
};
const payload = {
  subject: '',
  category: {},
  description: '',
  status: 'unresolved',
};
const status = {
  search: 'idle',
  orders: {
    count: 'idle',
    data: 'idle',
  },
  categories: 'idle',
  faqs: 'idle',
  likes: 'idle',
  dislikes: 'idle',
  uploadImages: 'idle',
  postFeedback: 'idle',
};
export const { actions, reducer } = createSlice({
  name: 'help',
  initialState: {
    keyword: '',
    count: 0,
    search,
    result, // flag
    contentOffset: { x: 0, y: 0 },
    orders,
    categories: [],
    faqs: [],
    payload,
    showSearchBar: false,
    showModalOrders: false,
    status,
  },
  reducers: {
    setKeyword: (state, { payload }) => {
      state.keyword = payload;
    },
    setResult: (state, { payload: { keyword, data } }) => {
      state.result = { keyword, data };
    },
    setOrders: (state, { payload: [key, value] }) => {
      state.orders[key] = value;
    },
    setOrdersParams: (state, { payload: [key, value] }) => {
      state.orders.params[key] = value;
    },
    setPayload: (state, { payload: [key, value] }) => {
      state.payload[key] = value;
    },
    setState: (state, { payload: [key, value] }) => {
      state[key] = value;
    },
    setStatus: (state, { payload: [key, value] }) => {
      state.status[key] = value;
    },
    resetSearch: (state) => {
      state.search = search;
    },
    resetOrders: (state) => {
      state.orders = orders;
    },
    resetPayload: (state) => {
      state.payload = payload;
    },
    resetStatus: (state) => {
      state.status = status;
    },
  },
  extraReducers: {
    [getSearchResult.pending]: (state) => {
      state.status.search = 'pending';
    },
    [getSearchResult.fulfilled]: (
      state,
      { payload: [count, { data, categories }] },
    ) => {
      state.count = count;
      state.search = { data, categories };
      state.status.search = 'succeeded';
    },
    [getOrdersCount.pending]: (state) => {
      state.status.orders.count = 'pending';
    },
    [getOrdersCount.fulfilled]: (state, { payload }) => {
      state.orders.count = payload;
      state.status.orders.count = 'succeeded';
    },
    [getOrders.pending]: (state) => {
      state.status.orders.data = 'pending';
    },
    [getOrders.fulfilled]: (state, { payload }) => {
      if (payload?.length) {
        state.orders.data = state.orders.data.concat(payload || []);
        state.status.orders.data = 'succeeded';
      } else {
        state.status.orders.data = 'empty';
      }
    },
    [getCategories.pending]: (state) => {
      state.status.categories = 'pending';
    },
    [getCategories.fulfilled]: (state, { payload }) => {
      state.categories = payload || [];
      state.status.categories = 'succeeded';
    },
    [getFaqs.pending]: (state) => {
      state.status.faqs = 'pending';
    },
    [getFaqs.fulfilled]: (state, { payload }) => {
      state.faqs = payload;
      state.status.faqs = 'succeeded';
    },
    [postLikes.pending]: (state) => {
      state.status.likes = 'pending';
    },
    [postLikes.fulfilled]: (state) => {
      state.status.likes = 'succeeded';
    },
    [postDislikes.pending]: (state) => {
      state.status.dislikes = 'pending';
    },
    [postDislikes.fulfilled]: (state) => {
      state.status.dislikes = 'succeeded';
    },
    [uploadImages.pending]: (state) => {
      state.status.uploadImages = 'pending';
    },
    [uploadImages.fulfilled]: (state, { payload }) => {
      if (payload.length > 0) {
        state.payload.images = payload;
      }
      state.status.uploadImages = 'succeeded';
    },
    [postFeedback.pending]: (state) => {
      state.status.postFeedback = 'pending';
    },
    [postFeedback.fulfilled]: (state) => {
      state.status.postFeedback = 'succeeded';
    },
  },
});

export default reducer;
