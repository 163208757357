import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '~api/help';

export const getSearchResult = createAsyncThunk(
  'help/getSearchResult',
  async ({ keyword, limit = 10 }) =>
    await api.getSearchResult({
      filter: {
        keyword,
        post_type: 'help_page_article',
        platform: 'lilla',
        created_from: 'sociolla',
      },
      fields: 'title category',
      skip: 0,
      limit,
    }),
);
export const getOrdersCount = createAsyncThunk(
  'help/getOrdersCount',
  async (params) => await api.getOrdersCount(params),
);
export const getOrders = createAsyncThunk(
  'help/getOrders',
  async (params) => await api.getOrders(params),
);
export const getCategories = createAsyncThunk(
  'help/getCategories',
  async () =>
    await api.getCategories({
      filter: {
        type: 'help_page_article',
        platform: 'lilla',
        created_from: 'sociolla',
      },
    }),
);
export const getPosts = createAsyncThunk(
  'help/getPosts',
  async (params) => await api.getPosts(params),
);
export const getFaqs = createAsyncThunk(
  'help/getFaqs',
  async () =>
    await api.getPosts({
      filter: {
        post_type: 'help_page_article',
        platform: 'lilla',
        created_from: 'sociolla',
        is_active: true,
      },
      fields: 'title category app_version',
      skip: 0,
      limit: 5,
      sort: '-total_views',
    }),
);
export const fetchLikes = createAsyncThunk(
  'help/fetchLikes',
  async (payload) => await api.fetchLikes(payload),
);
export const postLikes = createAsyncThunk(
  'help/postLikes',
  async (payload) => await api.postLikes(payload),
);
export const postDislikes = createAsyncThunk(
  'help/postDislikes',
  async (payload) => await api.postDislikes(payload),
);
// multiple uploads
export const uploadImages = createAsyncThunk(
  'help/uploadImages',
  async (payload) => await api.uploadImages(payload),
);
export const postFeedback = createAsyncThunk(
  'help/postFeedback',
  async (images, { dispatch, getState }) => {
    if (images.length) {
      await dispatch(uploadImages(images));
    }
    return api.postFeedback(getState().help.payload);
  },
);
